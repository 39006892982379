ion-action-sheet.my-sheet-class {
  --background: #1584d4;
  --backdrop-opacity: 0.6;
  --button-background-selected: #0d6eb2;
  --button-color: #fff;
  --color: #fff;
  /* role: "destructive" button iOS styling override */
  --ion-color-danger: #bd1111;
}

ion-action-sheet.my-sheet-class .action-sheet-title {
  font-size: 24px;
}

.my-sheet-class .action-sheet-group.sc-ion-action-sheet {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-sheet-class
  .action-sheet-group.sc-ion-action-sheet
  .action-sheet-title.sc-ion-action-sheet {
  margin-bottom: 10px;
}

.my-sheet-class .custom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.my-sheet-class .custom-content img {
  max-width: 100%;
  height: auto;
}
